import qs from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import {
  Toast,
  ActivityIndicator,
} from 'antd-mobile';

import './App.css';
import Main from './containers/Main';
import Profile from './containers/Profile';
import EditBasic from './containers/EditBasicProfile';
import EditEducation from './containers/EditEducationProfile';
import Edit from './containers/Edit';
import Interview from './containers/Interview';
import SendMessage from './containers/SendMessage';
import StepInterviews from './containers/StepInterviews';
import UserAgreement from './containers/UserAgreement';
import Staff from './containers/Staff';

import liffHelper from './utils/liffHelper';
import {
  touch,
} from './actions/candidate';

class App extends Component {
  constructor(props) {
    super(props);
    const extension = qs.parse(decodeURIComponent(window.location.search).replace(/\?/g, '&'));

    if (extension.c) {
      this.touchCandidate({
        ...extension, // source, etc...
        id: extension.c,
      });
    } else {
      liffHelper.getProfile().then(profile => {
        this.touchCandidate({
          ...extension, // source, etc...
          ...profile,
          lineid: profile.userId,
          avatar: profile.pictureUrl,
        });
      }).catch((err) => {
        Toast.fail(err);
      });
    }
  }

  touchCandidate = data => {
    const { candidate } = this.props;
    candidate.id || this.props.touch(data);
  };

  render() {
    const {
      candidate,
    } = this.props;

    return (
      <div className="app">
        {
          (candidate.id && candidate.alive_flag) ? (
            <Router>
              <Switch>
                <Route exact path="/" component={Main} />
                <Route path="/profile" component={Profile} />
                <Route path="/edit/basic" component={EditBasic} />
                <Route path="/edit/education" component={EditEducation} />
                <Route path="/edit/:field" component={Edit} />
                <Route path="/interview/:id" component={Interview} />
                <Route path="/step-interviews" component={StepInterviews} />
                <Route path="/interviews/:batchNo" component={StepInterviews} />
                <Route path="/send-message" component={SendMessage} />
                <Route path="/user-agreement" component={UserAgreement} />
                <Route path="/staff" component={Staff} />
              </Switch>
            </Router>
          ) : (
            <ActivityIndicator toast size="large" text="loading" />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  candidate: state.candidate.data,
});
const mapDispatchToProps = dispatch => ({
  touch: (profile) => dispatch(touch(profile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
