const liff = window.liff;
let isInit = false;
let profile = {};

class liffHelper {
  init() {
    return new Promise((resolve, reject) => {
      if (!isInit) {
        liff.init({
            liffId: '1653447763-9kdLa2EV',
          })
          .then(() => {
            isInit = true;
            if (!liff.isLoggedIn()) {
              liff.login();
            }
            resolve();
          })
          .catch((err) => {
            alert(err);
            reject();
          });
      } else {
        resolve();
      }
    });
  }

  getLIFFInfo() {
    return liff;
  }

  getProfile() {
    return new Promise((resolve, reject) => {
      if (process.env.NODE_ENV === 'development') {
        resolve({
          "userId":"Udc6d267be4cbd924e69709ffb87dd349",
          "displayName":"金太郎",
          "pictureUrl":'https://obs.line-scdn.net/0hOYzHkbsaEHZHMTvWpKBvIXt0HhswHxY-P14LE2Q0RhY_BFcgeQUPQGBkR0U-A1UjKAJWQzFlTxU6/preview',
          "statusMessage":"Hello, LINE!"
        });
        return;
      }

      this.init()
        .then(() => {
          if (isInit && !profile.userId) {
            liff.getProfile()
              .then(pf => {
                profile = pf;
                resolve(profile);
              })
              .catch((err) => {
                console.log('get profile error', err);
                reject(err);
              });
          } else {
            resolve(profile)
          }
        })
        .catch(err => { reject(err) });
    });
  }

  closeWindow() {
    liff.closeWindow();
  }

  openWindow(url, external) {
    liff.openWindow({ url, external });
  }

  sendMessages(messages) {
    const messagesToSend = Array.isArray(messages) ? messages : [messages];
    return new Promise((resolve, reject) => {
      this.init()
        .then(() => {
          liff.sendMessages(messagesToSend)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};
export default new liffHelper();
