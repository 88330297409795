import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  ActivityIndicator,
  Button,
  List,
  TextareaItem,
  Toast,
  WhiteSpace,
  WingBlank,
} from 'antd-mobile';

import {
  getMessages,
  sendMessage,
} from '../actions/message';

const Item = List.Item;
const Brief = Item.Brief;

class SendMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      body: '',
    };
  }

  componentDidMount() {
    const { location } = this.props;

    if (location && location.state) {
      const { body } = location.state;
      this.setState({
        body,
      });
    }
  }

  getMessages = () => {
    const { candidate } = this.props;

    this.props.getMessages({
      $or: [{
        from: candidate.lineid,
      }, {
        to: candidate.lineid,
      }],
      pageSize: 5,
    });
  }

  onRequestChange = () => {
    const { body } = this.state;
    const { candidate } = this.props;

    this.props.sendMessage({
      id: candidate.id,
      title: '面接申込のお問い合わせ',
      body,
    }).then((data) => {
      this.setState({
        body: '',
      });
      Toast.success('面接申込しました。', 1, () => {
        this.props.history.replace(`/`);
      });
    }).catch((err) => {
      Toast.fail(err);
    });
  };

  render() {
    const { body } = this.state;
    const {
      messages: {
        loading,
        list,
      },
    } = this.props;
    return (
      <div>
        {
          (loading) && <ActivityIndicator toast size="large" text="loading" />
        }
        <List
          renderHeader={() => 'エントリーメッセージ'}
        >
          <TextareaItem
            value={body}
            onChange={body => this.setState({ body })}
            rows={7}
            autoHeight
            labelNumber={5}
            placeholder="説明会（一次面接、二次面接、最終面接）にエントリーいたします。&#10;希望する日程は下記の通りです。&#10;【候補日】&#10;●月×日　▲▲：▲▲～&#10;●月×日　▲▲：▲▲～&#10;●月×日　▲▲：▲▲～"
          />
        </List>
        <WhiteSpace size="xl" />
        <WingBlank>
          <Button
            type="primary"
            disabled={!body}
            onClick={this.onRequestChange}
          >
            提出
          </Button>
        </WingBlank>
        <WhiteSpace size="xl" />
        {
          list.length > 0 ? (
            <List renderHeader={() => '過去メッセージ一覧'}>
              {
                list.map(item =>(
                  <Item
                    key={item.id}
                    wrap
                    multipleLine
                  >
                    <Brief>
                      {item.body}
                    </Brief>
                  </Item>
                ))
              }
            </List>
          ) : (
            <WingBlank>
              <Button
                type="ghost"
                inline
                size="small"
                onClick={this.getMessages}
              >
                過去メッセージ
              </Button>
            </WingBlank>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  candidate: state.candidate.data,
  loading: state.message.loading,
  messages: state.messages,
});
const mapDispatchToProps = dispatch => ({
  getMessages: (params) => dispatch(getMessages(params)),
  sendMessage: (info) => dispatch(sendMessage(info)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SendMessage));
