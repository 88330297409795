import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List, InputItem, Button, Toast, ActivityIndicator } from 'antd-mobile';

import {
  send,
  verify,
} from '../actions/systemuser';


class Staff extends Component {
  state = {
    email: '',
    emailError: false,
    lineid: '',
    code: '',
    codeError: false,
  }

  componentDidMount() {
    const { candidate } = this.props;
    this.setState({
      lineid: candidate.lineid,
    })
  }

  onChange = (email) => {
    // eslint-disable-next-line
    this.setState({
      emailError: !/^[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email),
      email,
    });
  }

  onChangeCode = (code) => {
    // eslint-disable-next-line
    this.setState({
      codeError: code.length < 4,
      code,
    });
  }

  send = () => {
    const { email } = this.state;

    this.props.send(email).then(({ error, data }) => {
      if (error) {
        Toast.fail(`検証コード発行失敗しました: ${error}`);
        this.setState({
          code: '',
          codeError: false,
        });
      } else {
        Toast.success('検証コード発行しました。', 1);
      }
    }).catch((err) => {
      Toast.fail(err);
    });
  };

  verify = () => {
    const { email, lineid, code } = this.state;

    this.props.verify({
      email,
      lineid,
      code,
    }).then(({ error, data }) => {
      if (error) {
        Toast.fail(`バインド失敗しました: ${error}`);
      } else {
        Toast.success('バインドしました。', 1);
      }
    }).catch((err) => {
      Toast.fail(err);
    });
  };

  render() {
    const { systemuser } = this.props;
    const {
      email,
      emailError,
      code,
      codeError,
    } = this.state;
    return (
      <div>
        {
          (systemuser.loading) && <ActivityIndicator toast size="large" text="loading" />
        }
        <List
          renderHeader={systemuser.verified ? 'メールが検証済です' : 'メールを検証してください'}
        >
          <InputItem
            type="email"
            disabled={systemuser.loading || systemuser.sent}
            placeholder="taro@example.com"
            error={this.state.emailError}
            onChange={this.onChange}
            value={this.state.email}
          >
            メール
          </InputItem>
          {
            !systemuser.sent && (
              <List.Item>
                <Button
                  type="primary"
                  disabled={emailError || email.length === 0 || systemuser.loading || systemuser.sent}
                  style={{ marginRight: '4px' }}
                  onClick={this.send}
                >
                  検証コード発行
                </Button>
              </List.Item>
            )
          }
        </List>
        {
          systemuser.sent && !systemuser.verified && (
            <List
              renderHeader={() => 'メールから頂いたコードを入力してください'}
            >
              <InputItem
                type="number"
                placeholder="1234"
                error={this.state.codeError}
                onChange={this.onChangeCode}
                value={this.state.code}
              >
                検証コード
              </InputItem>
              <List.Item>
                <Button
                  type="primary"
                  disabled={codeError || code.length === 0 || systemuser.loading || systemuser.verified}
                  style={{ marginRight: '4px' }}
                  onClick={this.verify}
                >
                  バインド
                </Button>
              </List.Item>
            </List>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  candidate: state.candidate.data,
  systemuser: state.systemuser,
});
const mapDispatchToProps = dispatch => ({
  send: (email) => dispatch(send(email)),
  verify: (params) => dispatch(verify(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Staff));
