import { stringify } from 'qs';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  withRouter,
  Link,
} from 'react-router-dom';
import {
  ActivityIndicator,
  List,
  Modal,
  NoticeBar,
  Steps,
  Toast,
} from 'antd-mobile';

import {
  getInterviews,
} from '../actions/interview';
import { REMOTE_URL, validCandidate } from '../constants';
import {
  PrimaryInfo,
  BasicInfo,
  EducationInfo,
  QuestionnaireInfo,
  EtcInfo,
} from '../components/Profile';

const Step = Steps.Step;
const Item = List.Item;
const Brief = Item.Brief;

const getNextProgress = (progress, progresses) => {
  const index = progresses.indexOf(progress);

  if (index === progresses.length - 1) {
    return '';
  }
  return progresses[index + 1];
};

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1: false,
    };
  }

  componentDidMount() {
    const { candidate } = this.props;
    this.setState({
      modal1: !candidate.email,
    });

    const stepParams = {
      candidate: null,
      title: candidate.progress,
      progress: '1_未使用',
      startDate: {
        $gt: moment().format(),
      },
    };
    this.props.getInterviews(stepParams);
  }

  onClose = key => () => {
    this.setState({
      [key]: false,
    });
  }

  sendMessage2HR = () => {
    const {
      candidate,
      currentInterview,
      candidateProgresses,
    } = this.props;

    let progress = null;
    if (currentInterview && currentInterview.progress === '4_完了') {
      progress = getNextProgress(candidate.progress, candidateProgresses);
    } else {
      progress = candidate.progress;
    }

    if (progress) {
      const params = {
        category: 'line-template',
        key: `${progress}申請`,
      };
      fetch(`${REMOTE_URL}/api/line/props?${stringify(params)}`)
        .then(res => res.json())
        .then(({ list }) => {
          const body = list.length > 0 ? list[0].data : '';
          this.props.history.push({
            pathname: '/send-message',
            state: {
              body,
            },
          });
        }).catch((err) => {
          Toast.fail(err);
        });
    } else {
      this.props.history.push('/send-message');
    }
  };

  render() {
    const {
      candidate,
      currentInterview,
      myInterviews,
      loading,
      stepinterviews,
      candidateProgresses,
      history,
    } = this.props;

    const steps = candidateProgresses.map((s, i) => (
      <Step
        key={i}
        title={s.replace(/^\d+_(.+)$/, '$1')}
        description={
          (myInterviews.find(myI => myI.title === s) || { progress: '' })
            .progress.replace(/^\d+_(.+)$/, '$1')
        }
      />
    ));

    return (
      <div>
        {
          (loading) && <ActivityIndicator toast size="large" text="loading" />
        }
        <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}>
         {
           !candidate.email && 'メールアドレスを登録してください。'
         }
         {
           candidate.email && !validCandidate(candidate) && '基本情報、学校情報、応募の経緯を登録してください。'
         }
         {
           validCandidate(candidate) && ' 何かご相談がありましたら、気軽にお問い合わせ下さい。'
         }
        </NoticeBar>
        {
          validCandidate(candidate) ? (
            <List
              renderHeader={() => 'プロフィール'}
            >
              <Item
                thumb={(
                  <img
                    src={candidate.avatar}
                    alt={candidate.familyname}
                    style={{
                      borderRadius: '50%',
                      width: 45,
                      height: 45,
                      margin: 5,
                    }}
                  />
                )}
                multipleLine
                extra="編集"
                arrow="horizontal"
                onClick={() => this.props.history.push(`/profile`)}
              >
                {`${candidate.familyname} ${candidate.lastname}` || candidate.displayName}<Brief></Brief>
              </Item>
            </List>
          ) : (
            <>
              <PrimaryInfo
                candidate={candidate}
                history={history}
              />
              {
                candidate.email && (
                  <BasicInfo
                    candidate={candidate}
                    history={history}
                  />
                )
              }
              {
                candidate.email && (
                  <EducationInfo
                    candidate={candidate}
                    history={history}
                  />
                )
              }
              {
                candidate.email && (
                  <QuestionnaireInfo
                    candidate={candidate}
                    history={history}
                  />
                )
              }
              {
                candidate.email && (
                  <EtcInfo
                    candidate={candidate}
                    history={history}
                  />
                )
              }
            </>
          )
        }
        {
          currentInterview && (
            <List
              renderHeader={() => '次回面接日程'}
            >
              {
                // 手元に面接会がある
                <Item
                  key={currentInterview.id}
                  arrow="horizontal"
                  onClick={() => this.props.history.push(`/interview/${currentInterview.id}`)}
                  extra={`${currentInterview.hours}時間/${currentInterview.progress.replace(/^\d+_(.+)$/, '$1')}`}
                >
                  {moment(currentInterview.startDate).format('MM月DD日HH:mm')}
                </Item>
              }
            </List>
          )
        }
        {
          // 手元に面接会がない、一覧から申込
          validCandidate(candidate) && !currentInterview && stepinterviews.length > 0 && (
            <List
              renderHeader={() => `${candidate.progress.replace(/^\d+_(.+)$/, '$1')}申込`}
            >
              <Item
                arrow="horizontal"
                onClick={() => this.props.history.push(`/step-interviews`)}
                extra=""
              >
                一覧から選択
              </Item>
            </List>
          )
        }
        {
          // 次のステップへの申請
          validCandidate(candidate) && 
          !currentInterview &&
          stepinterviews.length === 0 && (
            <List
              renderHeader={() => `エントリーメッセージ送信`}
            >
              <Item
                arrow="horizontal"
                onClick={this.sendMessage2HR}
                extra=""
              >
                採用担当にメッセージを送る
              </Item>
            </List>
          )
        }
        {
          validCandidate(candidate) && (
          <List
            renderHeader={() => 'あなたの選考状況'}
          >
            <Steps
              current={candidateProgresses.indexOf(candidate.progress)}
              size="small"
              style={{
                padding: 20,
              }}
            >
              {steps}
            </Steps>
          </List>
          )
        }
        <Modal
          popup
          visible={this.state.modal1}
          title="ユーザー規約"
          animationType="slide-up"
          onClose={this.onClose('modal1')}
          maskClosable={false}
          footer={[{
            text: '同意します',
            onPress: () => {
              this.onClose('modal1')();
            },
          }]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div
            style={{
              maxHeight: 600,
              overflow: 'scroll',
              textAlign: 'left',
              margin: 8,
            }}
          >
＜個人情報の取得および利用について＞<br />
本サービスでは、住所・氏名・連絡先などの個人情報を入力いただきます。<br />
個人情報のご提供は、円滑な採用活動を目的とするものであり<br />
いただいた個人情報を第三者に開示・提供することはいたしません。<br />
<br />
＜本規約への同意と規約の適用範囲について＞<br />
あなたが当社サービスに登録し、当社サービスを使用する場合
本ユーザー規約に同意したものとみなされます。<br />
当社サービスの一部には、当社が随時更新する追加機能が適用される場合があります。
この追加機能は、本ユーザー規約の一部として適用されるものとします。<br />
また、本ユーザー規約は当社サービスを利用するすべてのユーザーに適用され、情報の登録有無を問わないものとします。<br />
          </div>
        </Modal>
        <div
          style={{
            textAlign: 'center',
            margin: 18,
          }}
        >
          <Link to='/user-agreement'>利用規約</Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  candidate: state.candidate.data,
  currentInterview: state.candidate.currentInterview,
  myInterviews: state.candidate.myInterviews,
  loading: state.candidate.loading,
  candidateProgresses: state.candidate.tags.candidateProgresses.sort(),
  interviewTitles: state.candidate.tags.interviewTitles,
  stepinterviews: state.stepinterviews.list,
});
const mapDispatchToProps = dispatch => ({
  getInterviews: (params) => dispatch(getInterviews(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main));
