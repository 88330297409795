export const REMOTE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3005' : '';
export const FILE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3005/files' : '/files';

export const fileUrl = f => (f.startsWith('http') ? f : `${FILE_URL}/${f}`);

// candidate
export const CANDIDATE_TOUCH_REQUEST = 'CANDIDATE_TOUCH_REQUEST';
export const CANDIDATE_TOUCH_SUCCESS = 'CANDIDATE_TOUCH_SUCCESS';
export const CANDIDATE_TOUCH_FAILURE = 'CANDIDATE_TOUCH_FAILURE';


export const CANDIDATE_ADDRESS_REQUEST = 'CANDIDATE_ADDRESS_REQUEST';
export const CANDIDATE_ADDRESS_SUCCESS = 'CANDIDATE_ADDRESS_SUCCESS';
export const CANDIDATE_ADDRESS_FAILURE = 'CANDIDATE_ADDRESS_FAILURE';

// interview
export const INTERVIEW_CHOOSE_REQUEST = 'INTERVIEW_CHOOSE_REQUEST';
export const INTERVIEW_CHOOSE_SUCCESS = 'INTERVIEW_CHOOSE_SUCCESS';
export const INTERVIEW_CHOOSE_FAILURE = 'INTERVIEW_CHOOSE_FAILURE';

export const INTERVIEW_CONFIRM_REQUEST = 'INTERVIEW_CONFIRM_REQUEST';
export const INTERVIEW_CONFIRM_SUCCESS = 'INTERVIEW_CONFIRM_SUCCESS';
export const INTERVIEW_CONFIRM_FAILURE = 'INTERVIEW_CONFIRM_FAILURE';

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';

export const INTERVIEWS_REQUEST = 'INTERVIEWS_REQUEST';
export const INTERVIEWS_SUCCESS = 'INTERVIEWS_SUCCESS';
export const INTERVIEWS_FAILURE = 'INTERVIEWS_FAILURE';

export const INTERVIEW_REQUEST = 'INTERVIEW_REQUEST';
export const INTERVIEW_SUCCESS = 'INTERVIEW_SUCCESS';
export const INTERVIEW_FAILURE = 'INTERVIEW_FAILURE';

export const MESSAGES_REQUEST = 'MESSAGES_REQUEST';
export const MESSAGES_SUCCESS = 'MESSAGES_SUCCESS';
export const MESSAGES_FAILURE = 'MESSAGES_FAILURE';

export const PROPERTY_LIST_REQUEST = 'PROPERTY_LIST_REQUEST';
export const PROPERTY_LIST_SUCCESS = 'PROPERTY_LIST_SUCCESS';
export const PROPERTY_LIST_FAILURE = 'PROPERTY_LIST_FAILURE';

export const SYSTEMUSER_SEND_REQUEST = 'CANDIDATE_SEND_REQUEST';
export const SYSTEMUSER_SEND_SUCCESS = 'CANDIDATE_SEND_SUCCESS';
export const SYSTEMUSER_SEND_FAILURE = 'CANDIDATE_SEND_FAILURE';

export const SYSTEMUSER_VERIFY_REQUEST = 'CANDIDATE_VERIFY_REQUEST';
export const SYSTEMUSER_VERIFY_SUCCESS = 'CANDIDATE_VERIFY_SUCCESS';
export const SYSTEMUSER_VERIFY_FAILURE = 'CANDIDATE_VERIFY_FAILURE';

export const fieldTitles = {
  familyname: '姓',
  lastname: '名',
  familykana: '姓（カナ）',
  lastkana: '名（カナ）',
  avatar: '画像',
  files: '添付ファイル',
  email: 'メール',
  mobileno: '携帯番号',
  gender: '性別',
  birthday: '生年月日',
  age: '年齢',
  zipcode: '郵便番号',
  address: '住所',
  source: '応募サイト',
  university: '大学',
  faculty: '学部',
  department: '学科',
  graduateYear: '卒業年月',
  tap: '適性検査',
  preferInterview: '希望面接日時',
  new_graduate: '応募区分',
  progress: '進捗',
  memo: 'メモ',
};

export const fieldTips = {
  graduateYear: '※例:○○○○年3月 卒業見込み',
};

export const requiredFields = [
  'familyname',
  'lastname',
  'familykana',
  'lastkana',
  'avatar',
  'email',
  'mobileno',
  'gender',
  'birthday',
  'zipcode',
  'address',
  'university',
  'faculty',
  'department',
  'graduateYear',
  'new_graduate',
];

export const validCandidate = candidate => requiredFields.reduce((r, v) => r && candidate[v], true);
