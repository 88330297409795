import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  List,
  Toast,
  WingBlank,
  TextareaItem,
  Button,
  WhiteSpace,
  ActivityIndicator,
} from 'antd-mobile';

import {
  getInterview,
  confirm,
} from '../actions/interview';
import {
  sendMessage,
} from '../actions/message';

const Item = List.Item;
const Brief = Item.Brief;

class Interview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputMode: false,
      body: '',
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id }
      },
    } = this.props;

    this.props.getInterview(id);
  }

  onConfirm = () => {
    const { candidate, interview } = this.props;
    this.props.confirm({
      id: candidate.id,
      interview_id: interview.id,
    }).then(({ error }) => {
      if (error) {
        Toast.fail(`日程を承認失敗しました: ${error}`);
      } else {
        Toast.success('日程を承認しました。', 1, () => {
          this.props.history.goBack();
        });
      }
    }).catch((err) => {
      Toast.fail(err);
    });
  }

  onRequestChange = () => {
    const { body } = this.state;
    const { candidate } = this.props;

    this.props.sendMessage({
      id: candidate.id,
      title: '日程調整申請',
      body,
    }).then((data) => {
      this.setState({
        inputMode: false,
        body: '',
      });
      Toast.success('日程調整申請しました。', 1, () => {
        this.props.history.goBack();
      });
    }).catch((err) => {
      Toast.fail(err);
    });
  };

  render() {
    const { inputMode, body } = this.state;
    const { interview, loading } = this.props;
    return (
      <div>
        {
          (loading) && <ActivityIndicator toast size="large" text="loading" />
        }
        <List
          renderHeader={() => '面接日程詳細'}
        >
          <Item>
            選考状況
            <Brief>{(interview.title || '').replace(/^\d+_(.+)$/, '$1')}</Brief>
          </Item>
          <Item>
            ステータス
            <Brief>{(interview.progress || '').replace(/^\d+_(.+)$/, '$1')}</Brief>
          </Item>
          <Item>
            面接日時
            <Brief>{moment(interview.startDate).format('YYYY-MM-DD HH:mm')}</Brief>
          </Item>
          <Item>
            時間
            <Brief>{interview.hours}</Brief>
          </Item>
          {
            interview.memo && (
              <Item>
                備考
                <Brief>{interview.memo}</Brief>
              </Item>
            )
          }
          {
            !interview.candidateConfirmDate && (
              <Item>
                <Button type="primary" size="small" inline onClick={this.onConfirm}>承認</Button>
              </Item>
            )
          }
        </List>
        <WhiteSpace size="xl" />
        {
          !inputMode ? (
            <WingBlank>
              <Button
                inline
                type="ghost"
                onClick={() => {
                  this.setState({
                    inputMode: !inputMode,
                  });
                }}
              >
                日程調整申請...
              </Button>
            </WingBlank>
          ) : (
            <div>
              <List
                renderHeader={() => '日程調整申請'}
              >
                <TextareaItem
                  value={body}
                  onChange={body => this.setState({ body })}
                  rows={7}
                  autoHeight
                  labelNumber={5}
                  placeholder="ご希望の日程を入力してください"
                />
              </List>
              <WhiteSpace />
              <WingBlank>
                <Button
                  type="primary"
                  disabled={!body || body.length < 10}
                  onClick={this.onRequestChange}
                >
                  提出
                </Button>
              </WingBlank>
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  candidate: state.candidate.data,
  interview: state.interview.data,
  loading: state.interview.loading,
});
const mapDispatchToProps = dispatch => ({
  getInterview: (id) => dispatch(getInterview(id)),
  confirm: (params) => dispatch(confirm(params)),
  sendMessage: (info) => dispatch(sendMessage(info)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Interview));
