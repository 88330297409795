import { REMOTE_URL } from '../constants/';

import { CALL_API } from '../middleware/api';
import * as ACTION from '../constants';

export const send = (email) => ({
    [CALL_API]: {
    endpoint: `${REMOTE_URL}/api/line-pincode/send`,
    init: {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    },
    types: [
      ACTION.SYSTEMUSER_SEND_REQUEST,
      ACTION.SYSTEMUSER_SEND_SUCCESS,
      ACTION.SYSTEMUSER_SEND_FAILURE,
    ],
  },
});

export const verify = (params) => ({
    [CALL_API]: {
    endpoint: `${REMOTE_URL}/api/line-pincode/verify`,
    init: {
      method: 'POST',
      body: JSON.stringify(params),
    },
    types: [
      ACTION.SYSTEMUSER_VERIFY_REQUEST,
      ACTION.SYSTEMUSER_VERIFY_SUCCESS,
      ACTION.SYSTEMUSER_VERIFY_FAILURE,
    ],
  },
});
