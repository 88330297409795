import React, { Component } from 'react';

class UserAgreement extends Component {
  render() {
    return (
      <div
        style={{
          margin: 8,
        }}
      >
        <div
          style={{
            fontSize: 20,
            textAlign: 'center',
            margin: 16,
          }}
        >
          ＜個人情報の取得および利用について＞
        </div>
        <div
          style={{
            margin: 6,
          }}
        >
          本サービスでは、住所・氏名・連絡先などの個人情報を入力いただきます。
        </div>
        <div
          style={{
            margin: 6,
          }}
        >
          個人情報のご提供は、円滑な採用活動を目的とするものであり
        </div>
        <div
          style={{
            margin: 6,
          }}
        >
          いただいた個人情報を第三者に開示・提供することはいたしません。
        </div>
        <div
          style={{
            fontSize: 20,
            textAlign: 'center',
            margin: 16,
          }}
        >
          ＜本規約への同意と規約の適用範囲について＞
        </div>
        <div
          style={{
            margin: 6,
          }}
        >
          あなたが当社サービスに登録し、当社サービスを使用する場合
        </div>
        <div
          style={{
            margin: 6,
          }}
        >
          本ユーザー規約に同意したものとみなされます。
        </div>
        <div
          style={{
            margin: 6,
          }}
        >
          当社サービスの一部には、当社が随時更新する追加機能が適用される場合があります。
        </div>
        <div
          style={{
            margin: 6,
          }}
        >
          この追加機能は、本ユーザー規約の一部として適用されるものとします。
        </div>
        <div
          style={{
            margin: 6,
          }}
        >
          また、本ユーザー規約は当社サービスを利用するすべてのユーザーに適用され、情報の登録有無を問わないものとします。
        </div>
      </div>
    );
  }
}

export default UserAgreement;
