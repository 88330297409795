import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  PrimaryInfo,
  BasicInfo,
  EducationInfo,
  QuestionnaireInfo,
  EtcInfo,
} from '../components/Profile';

class Profile extends Component {
  render() {
    const { candidate, history } = this.props;
    return (
      <div>
        <PrimaryInfo
          candidate={candidate}
          history={history}
        />
        {
          candidate.email && (
            <BasicInfo
              candidate={candidate}
              history={history}
            />
          )
        }
        {
          candidate.email && (
            <EducationInfo
              candidate={candidate}
              history={history}
            />
          )
        }
        {
          candidate.email && (
            <QuestionnaireInfo
              candidate={candidate}
              history={history}
            />
          )
        }
        {
          candidate.email && (
            <EtcInfo
              candidate={candidate}
              history={history}
            />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  candidate: state.candidate.data,
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile));
