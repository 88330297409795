import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';

import './index.css';
import registerServiceWorker from './registerServiceWorker';


import configureStore from './store/configureStore';
import 'moment/locale/ja';

const store = configureStore();

render(
  <Provider store={store}>
    <App />
  </Provider>
    ,
  document.getElementById('root')
);
registerServiceWorker();
