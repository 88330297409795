import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createForm } from 'rc-form';
import moment from 'moment';
import {
  ActivityIndicator,
  Button,
  Radio,
  InputItem,
  Picker,
  DatePicker,
  ImagePicker,
  TextareaItem,
  List,
  Toast,
  WhiteSpace,
  WingBlank,
} from 'antd-mobile';

import {
  touch,
} from '../actions/candidate';
import { fieldTitles, fieldTips } from '../constants';

import { REMOTE_URL, fileUrl } from '../constants/';

const RadioItem = Radio.RadioItem;

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
      value: null,
      files: [],
      allKindData: [],
    };
  }

  componentDidMount() {
    const {
      candidate,
      tags,
      match: { params: { field } },
    } = this.props;
    this.inputRef && this.inputRef.focus();

    if (field === 'avatar' && candidate[field]) {
      this.setState({
        files: [{
          url: fileUrl(candidate[field]),
        }],
      });
    }

    this.setState({
      allKindData: [
        {
          field: 'email',
          entry: 'input',
          type: 'email',
        }, {
          field: 'mobileno',
          entry: 'input',
          type: undefined,
        }, {
          field: 'zipcode',
          entry: 'input',
          type: undefined,
        }, {
          field: 'graduateYear',
          entry: 'input',
        }, {
          field: 'avatar',
          entry: 'image-picker',
          type: undefined,
        }, {
          field: 'birthday',
          entry: 'date-picker',
          type: undefined,
        }, {
          field: 'preferInterview',
          entry: 'textarea',
          type: undefined,
        }, {
          field: 'a1',
          entry: 'textarea',
          type: undefined,
        }, {
          field: 'a2',
          entry: 'textarea',
          type: undefined,
        }, {
          field: 'a3',
          entry: 'textarea',
          type: undefined,
        }, {
          field: 'memo',
          entry: 'textarea',
          type: undefined,
        }, {
          field: 'gender',
          entry: 'radio',
          type: undefined,
          data: [
            { value: '男', label: '男' },
            { value: '女', label: '女' },
          ],
        }, {
          field: 'new_graduate',
          entry: 'picker',
          type: undefined,
          data: tags.candidateGraduates.map(graduate => ({ value: graduate, label: graduate })),
        }
      ]
    });
  }

  save = () => {
    const { value } = this.state;
    const {
      candidate,
      match: { params: { field } },
    } = this.props;

    let data = {
      lineid: candidate.lineid,
      [field]: value,
    };

    if (field === 'birthday') {
      const age = parseInt(moment(value, 'YYYY-MM-DD').fromNow(), 10);
      data = {
        ...data,
        age,
      };
    }

    this.props.touch(data).then(({ error, data }) => {
      if (error) {
        Toast.fail(`保存失敗しました: ${error}`);
      } else {
        Toast.success('保存しました。', 1, () => {
          this.props.history.goBack();
        });
      }
    }).catch((err) => {
      Toast.fail(err);
    });
  };

  change = (value, type) => {
    if (type === "email") {
      this.setState({
        changed: /^[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value),
        value,
      });
    } else {
      this.setState({
        changed: true,
        value,
      });
    }
  };

  onChangeImage = value => {
    if (value.length > 0) {
      this.setState({
        changed: false,
        files: value,
      });
      const opts = {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({ "url": value[0].url, "filename": value[0].file.name }),
      };

      fetch(`${REMOTE_URL}/api/line/upload/avatar?path=candidate`, opts)
        .then(res => res.json())
        .then((json) => {
          this.setState({
            changed: true,
            value: json.result,
          });
        }).catch((err) => {
          Toast.fail(err);
        });
    } else {
      this.setState({
        changed: true,
        value: '',
        files: [],
      });
    }
  };

  render() {
    const { value, changed, files, allKindData } = this.state;
    const {
      candidate,
      loading,
      form: { getFieldProps },
      match: { params: { field } },
    } = this.props;

    const kindData = allKindData.find(d => d.field === field);

    const type = kindData ? kindData.type : 'text';
    const entry = kindData ? kindData.entry : 'input';
    const data = kindData ? kindData.data : [];

    console.log('files', this.state.files);

    return (
      <div>
        {
          loading && <ActivityIndicator toast size="large" text="loading" />
        }
        <WhiteSpace />
        <List renderHeader={() => `${fieldTitles[field]} ${fieldTips[field] || ''} `}>
          {entry === 'input' && (
            <InputItem
              {...getFieldProps(field)}
              name={field}
              ref={el => this.inputRef = el}
              placeholder="入力してください"
              clear
              type={type}
              value={value != null ? value : candidate[field]}
              onChange={value => this.change(value, type)}
            />)}
          {entry === 'radio' &&
            data.map(i => (
              <RadioItem
                key={i.value}
                checked={(value || candidate[field]) === i.value}
                onChange={() => this.change(i.value, type)}
              >
                {i.label}
              </RadioItem>
            ))
          }
          {entry === 'date-picker' && (
            <DatePicker
              okText="OK"
              dismissText="キャンセル"
              extra="選択して下さい"
              mode="date"
              format="YYYY-MM-DD"
              minDate={moment().subtract(60, 'years').toDate()}
              value={moment(value || candidate[field]).toDate()}
              onChange={date => this.change(moment(date).format('YYYY-MM-DD'), type)}>
              <List.Item arrow="horizontal">{fieldTitles[field]}</List.Item>
            </DatePicker>
          )}
          {entry === 'textarea' && (
            <TextareaItem
              rows={7}
              placeholder="入力してください"
              value={value != null ? value : candidate[field]}
              onChange={value => this.change(value, type)}
            />
          )}
          {entry === 'picker' && (
            <Picker
              okText="OK"
              dismissText="キャンセル"
              extra="選択して下さい"
              data={data}
              value={[value != null ? value : candidate[field]]}
              cols={1}
              onChange={value => this.change(value[0], type)}
            >
              <List.Item arrow="horizontal">{fieldTitles[field]}</List.Item>
            </Picker>
          )}
          {entry === 'image-picker' && (
            <ImagePicker
              length={1}
              files={files}
              onChange={value => this.onChangeImage(value)}
              selectable={files < 1}
            />
          )}
        </List>
        <WhiteSpace size="xl" />
        <WingBlank>
          <Button
            disabled={!changed}
            type="primary"
            onClick={this.save}
          >
            保存
          </Button>
        </WingBlank>
        <WhiteSpace />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  candidate: state.candidate.data,
  tags: state.candidate.tags,
  loading: state.candidate.loading,
});
const mapDispatchToProps = dispatch => ({
  touch: (profile) => dispatch(touch(profile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(createForm()(Edit)));
