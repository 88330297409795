import { stringify } from 'qs';
import { REMOTE_URL } from '../constants/';

import { CALL_API } from '../middleware/api';
import * as ACTION from '../constants';

export const choose = ({ id, interview_id }) => ({
    [CALL_API]: {
    endpoint: `${REMOTE_URL}/api/line/interviews/choose`,
    init: {
      method: 'POST',
      body: JSON.stringify({
        id,
        interview_id,
      }),
    },
    types: [
      ACTION.INTERVIEW_CHOOSE_REQUEST,
      ACTION.INTERVIEW_CHOOSE_SUCCESS,
      ACTION.INTERVIEW_CHOOSE_FAILURE,
    ],
  },
});

export const confirm = ({ id, interview_id }) => ({
    [CALL_API]: {
    endpoint: `${REMOTE_URL}/api/line/interview/confirm`,
    init: {
      method: 'POST',
      body: JSON.stringify({
        id,
        interview_id,
      }),
    },
    types: [
      ACTION.INTERVIEW_CONFIRM_REQUEST,
      ACTION.INTERVIEW_CONFIRM_SUCCESS,
      ACTION.INTERVIEW_CONFIRM_FAILURE,
    ],
  },
});

export const getInterview = id => ({
  [CALL_API]: {
    endpoint: `${REMOTE_URL}/api/line/interview/${id}`,
    init: {
      method: 'GET',
    },
    types: [
      ACTION.INTERVIEW_REQUEST,
      ACTION.INTERVIEW_SUCCESS,
      ACTION.INTERVIEW_FAILURE,
    ],
  },
});

export const getInterviews = params => ({
  [CALL_API]: {
    endpoint: `${REMOTE_URL}/api/line/interviews?${stringify(params)}`,
    init: {
      method: 'GET',
    },
    types: [
      ACTION.INTERVIEWS_REQUEST,
      ACTION.INTERVIEWS_SUCCESS,
      ACTION.INTERVIEWS_FAILURE,
    ],
  },
});
