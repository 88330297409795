import { combineReducers } from 'redux';

import candidate from './candidate';
import systemuser from './systemuser';
import interview from './interview';
import message from './message';
import messages from './messages';
import properties from './properties';
import stepinterviews from './stepinterviews';

const rootReducer = combineReducers({
  candidate,
  systemuser,
  interview,
  message,
  messages,
  properties,
  stepinterviews,
});

export default rootReducer;
