import { stringify } from 'qs';
import { REMOTE_URL } from '../constants/';

import { CALL_API } from '../middleware/api';
import * as ACTION from '../constants';

export const sendMessage = ({ id, title, body }) => ({
    [CALL_API]: {
    endpoint: `${REMOTE_URL}/api/line/send-message`,
    init: {
      method: 'POST',
      body: JSON.stringify({
        id,
        title,
        body,
      }),
    },
    types: [
      ACTION.SEND_MESSAGE_REQUEST,
      ACTION.SEND_MESSAGE_SUCCESS,
      ACTION.SEND_MESSAGE_FAILURE,
    ],
  },
});

export const getMessages = params => ({
  [CALL_API]: {
    endpoint: `${REMOTE_URL}/api/line/messages?${stringify(params)}`,
    init: {
      method: 'GET',
    },
    types: [
      ACTION.MESSAGES_REQUEST,
      ACTION.MESSAGES_SUCCESS,
      ACTION.MESSAGES_FAILURE,
    ],
  },
});
