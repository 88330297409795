import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createForm } from 'rc-form';
import {
  ActivityIndicator,
  Button,
  InputItem,
  Picker,
  List,
  Toast,
  WhiteSpace,
  WingBlank,
} from 'antd-mobile';

import {
  touch,
} from '../actions/candidate';
import { fieldTitles, fieldTips } from '../constants';

class EditEducationProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
      university: null,
      faculty: null,
      department: null,
      new_graduate: null,
      graduateYear: null,
      new_graduates: [],
    };
  }

  componentDidMount() {
    const {
      candidate,
      tags,
    } = this.props;
    this.setState({
      university: candidate.university,
      faculty: candidate.faculty,
      department: candidate.department,
      new_graduate: candidate.new_graduate,
      graduateYear: candidate.graduateYear,
      new_graduates: tags.candidateGraduates.map(graduate => ({ value: graduate, label: graduate })),
    });
    this.inputRef && this.inputRef.focus();
  }

  save = () => {
    const { candidate } = this.props;

    const data = {
      lineid: candidate.lineid,
      ...this.state,
    };

    this.props.touch(data).then(({ error, data }) => {
      if (error) {
        Toast.fail(`保存失敗しました: ${error}`);
      } else {
        Toast.success('保存しました。', 1, () => {
          this.props.history.goBack();
        });
      }
    }).catch((err) => {
      Toast.fail(err);
    });
  };

  render() {
    const { university, faculty, department, new_graduate, graduateYear, new_graduates, changed } = this.state;
    const {
      candidate,
      loading,
      form: { getFieldProps },
    } = this.props;

    return (
      <div>
        {
          loading && <ActivityIndicator toast size="large" text="loading" />
        }
        <WhiteSpace />
        <List renderHeader={() => `${fieldTitles['university']} ${fieldTips['university'] || ''} `}>
          <InputItem
            {...getFieldProps('university')}
            name='university'
            ref={el => this.inputRef = el}
            placeholder="入力してください"
            clear
            type='text'
            value={university != null ? university : candidate['university']}
            onChange={
              value => this.setState({
                changed: true,
                university: value,
              })
            }
          />
        </List>
        <List renderHeader={() => `${fieldTitles['faculty']} ${fieldTips['faculty'] || ''} `}>
          <InputItem
            {...getFieldProps('faculty')}
            name='faculty'
            placeholder="入力してください"
            clear
            type='text'
            value={faculty != null ? faculty : candidate['faculty']}
            onChange={
              value => this.setState({
                changed: true,
                faculty: value,
              })
            }
          />
        </List>
        <List renderHeader={() => `${fieldTitles['department']} ${fieldTips['department'] || ''} `}>
          <InputItem
            {...getFieldProps('department')}
            name='department'
            placeholder="入力してください"
            clear
            type='text'
            value={department != null ? department : candidate['department']}
            onChange={
              value => this.setState({
                changed: true,
                department: value,
              })
            }
          />
        </List>
        <List renderHeader={() => `${fieldTitles['new_graduate']} ${fieldTips['new_graduate'] || ''} `}>
          <Picker
            okText="OK"
            dismissText="キャンセル"
            extra="選択して下さい"
            data={new_graduates}
            value={[new_graduate != null ? new_graduate : candidate['new_graduate']]}
            cols={1}
            onChange={
              value => this.setState({
                changed: true,
                new_graduate: value[0],
              })
            }
          >
            <List.Item arrow="horizontal">{fieldTitles['new_graduate']}</List.Item>
          </Picker>
        </List>
        <List renderHeader={() => `${fieldTitles['graduateYear']} ${fieldTips['graduateYear'] || ''} `}>
          <InputItem
            {...getFieldProps('graduateYear')}
            name='graduateYear'
            placeholder="入力してください"
            clear
            type='text'
            value={graduateYear != null ? graduateYear : candidate['graduateYear']}
            onChange={
              value => this.setState({
                changed: true,
                graduateYear: value,
              })
            }
          />
        </List>
        <WhiteSpace size="xl" />
        <WingBlank>
          <Button
            disabled={!changed}
            type="primary"
            onClick={this.save}
          >
            保存
          </Button>
        </WingBlank>
        <WhiteSpace />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  candidate: state.candidate.data,
  tags: state.candidate.tags,
  loading: state.candidate.loading,
});
const mapDispatchToProps = dispatch => ({
  touch: (profile) => dispatch(touch(profile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(createForm()(EditEducationProfile)));
