import { REMOTE_URL } from '../constants/';

import { CALL_API } from '../middleware/api';
import * as ACTION from '../constants';

export const touch = (profile) => ({
    [CALL_API]: {
    endpoint: `${REMOTE_URL}/api/line/candidate/touch`,
    init: {
      method: 'POST',
      body: JSON.stringify(profile),
    },
    types: [
      ACTION.CANDIDATE_TOUCH_REQUEST,
      ACTION.CANDIDATE_TOUCH_SUCCESS,
      ACTION.CANDIDATE_TOUCH_FAILURE,
    ],
  },
});

export const address = (zipcode) => ({
    [CALL_API]: {
    endpoint: `${REMOTE_URL}/api/line/address?zipcode=${zipcode}`,
    init: {
      method: 'GET',
    },
    types: [
      ACTION.CANDIDATE_ADDRESS_REQUEST,
      ACTION.CANDIDATE_ADDRESS_SUCCESS,
      ACTION.CANDIDATE_ADDRESS_FAILURE,
    ],
  },
});
 
