import * as ACTION from '../constants';

const INITIAL_STATE = {
  error: null,
  loading: false,
  sent: false,
  verified: false,
};

export default function (state = INITIAL_STATE, action) {

  switch (action.type) {
    case ACTION.SYSTEMUSER_SEND_REQUEST:
    case ACTION.SYSTEMUSER_VERIFY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ACTION.SYSTEMUSER_SEND_SUCCESS:
      return {
        ...state,
        loading: false,
        sent: true,
        error: null,
      };
    case ACTION.SYSTEMUSER_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        verified: true,
        error: null,
      };
    case ACTION.SYSTEMUSER_SEND_FAILURE:
    case ACTION.SYSTEMUSER_VERIFY_FAILURE:
      return {
        ...state,
        loading: false,
        sent: false,
        verified: false,
        error: action.error,
      };
    default:
      return state;
  }
}
