import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createForm } from 'rc-form';
import moment from 'moment';
import {
  ActivityIndicator,
  Button,
  Radio,
  InputItem,
  DatePicker,
  List,
  Toast,
  WhiteSpace,
  WingBlank,
} from 'antd-mobile';

import {
  touch,
  address,
} from '../actions/candidate';
import { fieldTitles, fieldTips } from '../constants';

const RadioItem = Radio.RadioItem;

class EditBasicProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
      familyname: null,
      lastname: null,
      familykana: null,
      lastkana: null,
      mobileno: null,
      gender: null,
      birthday: null,
      zipcode: null,
      address: null,
    };
  }

  componentDidMount() {
    const {
      candidate,
    } = this.props;
    this.setState({
      familyname: candidate.familyname,
      lastname: candidate.lastname,
      familykana: candidate.familykana,
      lastkana: candidate.lastkana,
      mobileno: candidate.mobileno,
      gender: candidate.gender,
      birthday: candidate.birthday,
      zipcode: candidate.zipcode,
      address: candidate.address,
    });
    this.inputRef && this.inputRef.focus();
  }

  save = () => {
    const { birthday } = this.state;
    const { candidate } = this.props;

    let data = {
      lineid: candidate.lineid,
      ...this.state,
    };

    if (birthday && birthday !== null) {
      data = {
        lineid: candidate.lineid,
        ...this.state,
      };
    }

    this.props.touch(data).then(({ error, data }) => {
      if (error) {
        Toast.fail(`保存失敗しました: ${error}`);
      } else {
        Toast.success('保存しました。', 1, () => {
          this.props.history.goBack();
        });
      }
    }).catch((err) => {
      Toast.fail(err);
    });
  };

  address = (zipcode) => {
    if (zipcode) {
      this.props.address(zipcode.replace(/\s*/g,"")).then(({ error, address }) => {
        this.setState({
          address
        })
      })
    }
  };

  render() {
    const {
      familyname,
      lastname,
      familykana,
      lastkana,
      mobileno,
      gender,
      birthday,
      zipcode,
      address,
      changed
    } = this.state;
    const {
      candidate,
      loading,
      form: { getFieldProps },
    } = this.props;

    return (
      <div>
        {
          loading && <ActivityIndicator toast size="large" text="loading" />
        }
        <List renderHeader={() => `${fieldTitles['familyname']} ${fieldTips['familyname'] || ''} `}>
          <InputItem
            {...getFieldProps('familyname')}
            name='familyname'
            ref={el => this.inputRef = el}
            placeholder="入力してください"
            clear
            type='text'
            value={familyname != null ? familyname : candidate['familyname']}
            onChange={
              value => this.setState({
                changed: true,
                familyname: value.replace(/[^\u4E00-\u9FA5|ア-ンァャュョヴ　－]/ig,''),
              })
            }
          />
        </List>
        <List renderHeader={() => `${fieldTitles['lastname']} ${fieldTips['lastname'] || ''} `}>
          <InputItem
            {...getFieldProps('lastname')}
            name='lastname'
            placeholder="入力してください"
            clear
            type='text'
            value={lastname != null ? lastname : candidate['lastname']}
            onChange={
              value => this.setState({
                changed: true,
                lastname: value.replace(/[^\u4E00-\u9FA5|ア-ンァャュョヴ　－]/ig,''),
              })
            }
          />
        </List>
        <List renderHeader={() => `${fieldTitles['familykana']} ${fieldTips['familykana'] || ''} `}>
          <InputItem
            {...getFieldProps('familykana')}
            name='familykana'
            placeholder="入力してください"
            clear
            type='text'
            value={familykana != null ? familykana : candidate['familykana']}
            onChange={
              value => this.setState({
                changed: true,
                familykana: value.replace(/[^ア-ンァャュョヴ　－]/ig,''),
              })
            }
          />
        </List>
        <List renderHeader={() => `${fieldTitles['lastkana']} ${fieldTips['lastkana'] || ''} `}>
          <InputItem
            {...getFieldProps('lastkana')}
            name='lastkana'
            placeholder="入力してください"
            clear
            type='text'
            value={lastkana != null ? lastkana : candidate['lastkana']}
            onChange={
              value => this.setState({
                changed: true,
                lastkana: value.replace(/[^ア-ンァャュョヴ　－]/ig,''),
              })
            }
          />
        </List>
        <List renderHeader={() => `${fieldTitles['mobileno']} ${fieldTips['mobileno'] || ''} ※ハイフンなしで数字のみ入力してください`}>
          <InputItem
            {...getFieldProps('mobileno')}
            name='mobileno'
            placeholder="入力してください"
            clear
            type='phone'
            value={mobileno != null ? mobileno : candidate['mobileno']}
            onChange={
              value => this.setState({
                changed: true,
                mobileno: value.replace(/\ /g, '-'),
              })
            }
          />
        </List>
        <List renderHeader={() => `${fieldTitles['gender']} ${fieldTips['gender'] || ''} `}>
          <RadioItem
            key='男'
            checked={(gender || candidate['gender']) === '男'}
            onChange={
              () => this.setState({
                changed: true,
                gender: '男',
              })
            }
          >
            男
          </RadioItem>
          <RadioItem
            key='女'
            checked={(gender || candidate['gender']) === '女'}
            onChange={
              () => this.setState({
                changed: true,
                gender: '女',
              })
            }
          >
            女
          </RadioItem>
        </List>
        <List renderHeader={() => `${fieldTitles['birthday']} ${fieldTips['birthday'] || ''} `}>
          <DatePicker
            okText="OK"
            dismissText="キャンセル"
            extra="選択して下さい"
            mode="date"
            format="YYYY-MM-DD"
            minDate={moment().subtract(60, 'years').toDate()}
            maxDate={moment().subtract(1, 'years').endOf('years').toDate()}
            value={moment(birthday || candidate['birthday']).toDate()}
            onChange={
              value => this.setState({
                changed: true,
                birthday: moment(value).format('YYYY-MM-DD'),
              })
            }
          >
            <List.Item arrow="horizontal">{fieldTitles['birthday']}</List.Item>
          </DatePicker>
        </List>
        <List renderHeader={() => `${fieldTitles['zipcode']} ${fieldTips['zipcode'] || ''} ※ハイフンなしで数字のみ入力してください`}>
          <InputItem
            {...getFieldProps('zipcode')}
            name='zipcode'
            placeholder="入力してください"
            clear
            maxLength='8'
            type='phone'
            value={zipcode != null ? zipcode : candidate['zipcode']}
            onChange={
              value => this.setState({
                changed: true,
                zipcode: value.replace(/\ /g, '-'),
              })
            }
            onBlur={
              value => this.address(value)
            }
          />
        </List>
        <List renderHeader={() => `${fieldTitles['address']} ${fieldTips['address'] || ''} `}>
          <InputItem
            {...getFieldProps('address')}
            name='address'
            placeholder="入力してください"
            clear
            type='text'
            value={address != null ? address : candidate['address']}
            onChange={
              value => this.setState({
                changed: true,
                address: value,
              })
            }
          />
        </List>
        <WhiteSpace size="xl" />
        <WingBlank>
          <Button
            disabled={!changed}
            type="primary"
            onClick={this.save}
          >
            保存
          </Button>
        </WingBlank>
        <WhiteSpace />
        <WhiteSpace size="xl" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  candidate: state.candidate.data,
  loading: state.candidate.loading,
});
const mapDispatchToProps = dispatch => ({
  touch: (profile) => dispatch(touch(profile)),
  address: (zipcode) => dispatch(address(zipcode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(createForm()(EditBasicProfile)));
