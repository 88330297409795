import * as ACTION from '../constants';

const INITIAL_STATE = {
  error: null,
  loading: false,
  data: {
    interview: {},
  },
  currentInterview: null,
  myInterviews: [],
  tags: {
    interviewTitles: [],
    candidateGraduates: [],
    candidateProgresses: [],
  },
  address: null,
};

export default function (state = INITIAL_STATE, action) {

  switch (action.type) {
    case ACTION.CANDIDATE_TOUCH_REQUEST:
    case ACTION.INTERVIEW_CHOOSE_REQUEST:
      return {
        ...state,
        currentInterview: null,
        myInterviews: [],
        tags: {
          interviewTitles: [],
          candidateGraduates: [],
          candidateProgresses: [],
        },
        loading: true,
        error: null,
      };
    case ACTION.CANDIDATE_TOUCH_SUCCESS:
    case ACTION.INTERVIEW_CHOOSE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data || {},
        currentInterview: action.currentInterview,
        myInterviews: action.myInterviews,
        tags: action.tags,
        error: null,
      };
    case ACTION.CANDIDATE_TOUCH_FAILURE:
    case ACTION.INTERVIEW_CHOOSE_FAILURE:
      return {
        ...state,
        currentInterview: null,
        myInterviews: [],
        tags: {
          interviewTitles: [],
          candidateGraduates: [],
          candidateProgresses: [],
        },
        loading: false,
        error: action.error,
      };
    case ACTION.CANDIDATE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ACTION.CANDIDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        address: action.address || {},
        error: null,
      };
    case ACTION.CANDIDATE_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
