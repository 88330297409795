import React from 'react';
import { List, Badge, Button, } from 'antd-mobile';

import { fieldTitles, requiredFields, fileUrl } from '../constants';

const Item = List.Item;
const Brief = Item.Brief;

export const PrimaryInfo = ({ candidate, history }) => (
  <List
    renderHeader={() => 'アカウント情報'}
  >
    <Item
      arrow="horizontal"
      onClick={() => history.push('/edit/avatar')}
      thumb={
        <img
          src={candidate.avatar ? fileUrl(candidate.avatar) : candidate.avatar}
          alt={candidate.displayName}
          style={{
            borderRadius: '50%',
            width: 45,
            height: 45,
            margin: 5,
          }}
        />
      }
      multipleLine
    >
      {candidate.displayName} <Brief></Brief>
    </Item>
    <Item
      extra={!candidate.email && requiredFields.indexOf('email') > -1 && <Badge dot />}
      arrow="horizontal"
      onClick={() => history.push('/edit/email')}
    >
      {fieldTitles['email']}
      <Brief>{candidate.email}</Brief>
    </Item>
  </List>
);

export const BasicInfo = ({ candidate, history }) => (
  <List
    renderHeader={() => <div style={{height: '25px', lineHeight: '34px', marginTop: '-7px', marginBottom: '-2px'}}>
      <span>基本情報</span>
      <Button
        type="primary"
        size="small"
        inline
        style={{height: '25px', float: 'right'}}
        onClick={() => history.push('/edit/basic')}
      >
        編集
      </Button>
    </div>}
  >
    <Item
      extra={(!candidate.familyname && requiredFields.indexOf('familyname') > -1) ? <Badge dot /> : candidate.familyname}
    >
      {fieldTitles['familyname']}
      <Brief></Brief>
    </Item>
    <Item
      extra={(!candidate.lastname && requiredFields.indexOf('lastname') > -1) ? <Badge dot /> : candidate.lastname}
    >
      {fieldTitles['lastname']}
      <Brief></Brief>
    </Item>
    <Item
      extra={(!candidate.familykana && requiredFields.indexOf('familykana') > -1) ? <Badge dot /> : candidate.familykana}
    >
      {fieldTitles['familykana']}
      <Brief></Brief>
    </Item>
    <Item
      extra={(!candidate.lastkana && requiredFields.indexOf('lastkana') > -1) ? <Badge dot /> : candidate.lastkana}
    >
      {fieldTitles['lastkana']}
      <Brief></Brief>
    </Item>
    <Item
      extra={(!candidate.mobileno && requiredFields.indexOf('mobileno') > -1) ? <Badge dot /> : candidate.mobileno}
    >
      {fieldTitles['mobileno']}
      <Brief></Brief>
    </Item>
    <Item
      extra={(!candidate.gender && requiredFields.indexOf('gender') > -1) ? <Badge dot /> : candidate.gender}
    >
      {fieldTitles['gender']}
      <Brief></Brief>
    </Item>
    <Item
      extra={(!candidate.birthday && requiredFields.indexOf('birthday') > -1) ? <Badge dot /> : candidate.birthday}
    >
      {fieldTitles['birthday']}
      <Brief></Brief>
    </Item>
    <Item
      extra={(!candidate.zipcode && requiredFields.indexOf('zipcode') > -1) ? <Badge dot /> : candidate.zipcode}
    >
      {fieldTitles['zipcode']}
      <Brief></Brief>
    </Item>
    <Item
      extra={(!candidate.address && requiredFields.indexOf('address') > -1) ? <Badge dot /> : candidate.address}
    >
      {fieldTitles['address']}
      <Brief></Brief>
    </Item>
  </List>
);
export const EducationInfo = ({ candidate, history }) => (
  <List
    renderHeader={() => <div style={{height: '25px', lineHeight: '34px', marginTop: '-7px', marginBottom: '-2px'}}>
      <span>学校情報</span>
      <Button
        type="primary"
        size="small"
        inline
        style={{height: '25px', float: 'right'}}
        onClick={() => history.push('/edit/education')}
      >
        編集
      </Button>
    </div>}
  >
    <Item
      extra={(!candidate.university && requiredFields.indexOf('university') > -1) ? <Badge dot /> : candidate.university}
    >
      {fieldTitles['university']}
      <Brief></Brief>
    </Item>
    <Item
      extra={(!candidate.faculty && requiredFields.indexOf('faculty') > -1) ? <Badge dot /> : candidate.faculty}
    >
      {fieldTitles['faculty']}
      <Brief></Brief>
    </Item>
    <Item
      extra={(!candidate.department && requiredFields.indexOf('department') > -1) ? <Badge dot /> : candidate.department}
    >
      {fieldTitles['department']}
      <Brief></Brief>
    </Item>
    <Item
      extra={(!candidate.new_graduate && requiredFields.indexOf('new_graduate') > -1) ? <Badge dot /> : candidate.new_graduate}
    >
      {fieldTitles['new_graduate']}
      <Brief></Brief>
    </Item>
    <Item
      extra={(!candidate.graduateYear && requiredFields.indexOf('graduateYear') > -1) ? <Badge dot /> : candidate.graduateYear}
    >
      {fieldTitles['graduateYear']}
      <Brief></Brief>
    </Item>
  </List>
);
export const QuestionnaireInfo = ({ candidate, history }) => (
  <>
  </>
);
export const EtcInfo = ({ candidate, history }) => (
  <>
  </>
);
