import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
  ActivityIndicator,
  Button,
  List,
  Toast,
  Radio,
  WhiteSpace,
  WingBlank,
} from 'antd-mobile';

import {
  getInterviews,
  choose,
} from '../actions/interview';
import liffHelper from '../utils/liffHelper';

const RadioItem = Radio.RadioItem;
const { Item } = List;
const Brief = Item.Brief;

class InterviewList extends Component {
  state = {
    selectedInterviewId: null,
  };

  componentDidMount() {
    const {
      candidate,
      match: {
        params: { batchNo }
      },
    } = this.props;

    const params = {
      candidate: null,
      batchNo,
      title: candidate.progress,
      progress: '1_未使用',
      startDate: {
        $gt: moment().format(),
      },
    };

    this.props.getInterviews(params);
  }

  onChange = (selectedInterviewId) => {
    this.setState({
      selectedInterviewId,
    });
  };

  onChoose = () => {
    const { selectedInterviewId } = this.state;
    const { candidate } = this.props;

    this.props.choose({
      id: candidate.id,
      interview_id: selectedInterviewId,
    }).then((data) => {
      Toast.success('選択しました。', 1, () => {
        liffHelper.closeWindow();
      });
    }).catch((err) => {
      Toast.fail(err);
    });
  };

  render() {
    const { selectedInterviewId } = this.state;
    const {
      candidate,
      currentInterview,
      interviews: {
        loading,
        list,
      },
    } = this.props;

    return (
      <div>
        {
          (loading) && <ActivityIndicator toast size="large" text="loading" />
        }
        <List
          renderHeader={() => `ご希望の日程「${candidate.progress.replace(/^\d+_(.+)$/, '$1')}」を選択して下さい`}
        >
          {
            list.map(item =>(
              item.app_show !== false && (
                <RadioItem
                  key={item.id}
                  checked={selectedInterviewId === item.id} onChange={() => this.onChange(item.id)}
                  wrap
                  multipleLine
                >
                  {moment(item.startDate).format('YYYY-MM-DD HH:mm')}から
                  <Brief>
                    {`${item.hours}時間`}
                  </Brief>
                </RadioItem>
              )
            ))
          }
        </List>
        <WhiteSpace size="xl" />
        <WingBlank>
          <Button
            onClick={this.onChoose}
            disabled={!selectedInterviewId || currentInterview}
            type="primary"
          >
            送信
          </Button>
        </WingBlank>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  candidate: state.candidate.data,
  currentInterview: state.candidate.currentInterview,
  interviews: state.stepinterviews,
});
const mapDispatchToProps = dispatch => ({
  getInterviews: (params) => dispatch(getInterviews(params)),
  choose: (body) => dispatch(choose(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InterviewList));
